import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import { withRouter } from "react-router";
import "./Footer.scss";
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";

@inject("globalStore")
@observer class Footer extends Component {
  constructor(props) {
    super(props);
    this.submitNewsLetter = this.submitNewsLetter.bind(this)
    this.state = {
      success: false,
      email:'',
      showLoader:false,
    }
  }

  componentDidMount() {
    console.log('PROPS', this.props)
  }

  handleChange = (e) => {
    if(e.target.value === ""){
      let elem = document.getElementsByName(e.target.name)
      elem[0].classList.add("error");
    }else{
      let elem = document.getElementsByName(e.target.name)
      elem[0].classList.remove("error");
    }

    this.setState({
      email: e.target.value
    })
  }
  submitNewsLetter = () => {
    if(this.state.email !== ''){
    this.setState({ showLoader : true})
    this.props.globalStore.postNewsLetter(this.state.email).then((resp) => {
      this.setState({email: ''})
      this.setState({success: true})
    }).finally(()=>{
      this.setState({
        showLoader:false
      })
    });
  } else{
    let elem = document.getElementsByClassName('form-control')
    elem[0].classList.add('error');
  }
}

  render() {
    if(this.props.location.pathname.toLowerCase() === '/vgcc') return null
    return (
      <div>
        <footer class="container-xl"
        className={
          (this.props.location.pathname === '/join-us' 
          || this.props.location.pathname === '/about-us') 
          || this.props.location.pathname.split("/")[1] === 'care-pathways'
          ||  (this.props.location.pathname === '/privacy')
          || (this.props.location.pathname === '/terms') 
          || (this.props.location.pathname === '/for-patient'
          || this.props.location.pathname === '/landing/msk'
          || this.props.location.pathname === '/landing/msk2' )? 'footer-plain' : ''
          }
        >
          <div class="about-us" className={(this.props.location.pathname === '/about-us') ? 'about-us footer-about' : 'about-us' }>
            <p>DoctorPlan helps healthcare organizations create deep relationships with patients, using a data-driven approach to personalize care delivery. 
              Our personalized health platform helps them deliver the best outcomes for patients — with extended care, access to timely information, and personalized follow ups, between visits.</p>
            { (this.props.location.pathname !== '/about-us') ? <Link href="" to="/about-us" class="learn-more-about">Learn More About DoctorPlan
               <img src={require("../../img/arrow-black.svg")} alt="" /> 
           </Link> : null }
          </div>
          <div class="container">
            { (this.props.location.pathname !== '/join-us') ?             
            <div class="mail-us">
              <p class="text-center footer-font">
                <strong>Curious to learn more?</strong>
                <Link class="footer-contact-us" to="/join-us" href="">Contact Us</Link>
              </p>
            </div> : null}

            <div class="footer-bottom">
              <div class="row justify-content-lg-between justify-content-center customRow">
                <div class="col-lg-auto">
                  <p>© { new Date().getFullYear() } DoctorBox Health Inc.</p>
                  <p>10 Audrey Ct., Tiburon CA 94920, United States</p>
                </div>
                <div class="col-lg-auto">
                  <p style={{display:"flex",justifyContent:"center"}}>
                    <Link to="/terms">Terms of Use</Link>
                    <div style={{borderRadius: "16px", marginTop: "9px", height: "4px", width: "4px", background: "#eeeeee"}}></div>
                    <Link to="/privacy">Privacy</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default withRouter(Footer)